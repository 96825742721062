<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <v-card v-else>
      <v-card-title> Daftar Timeline </v-card-title>
      <v-data-table
        :headers="headers"
        hide-default-footer
        :items="datas"
        :items-per-page="perPage"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr>
            <td>
              <a
                target="_blank"
                :href="`${baseWebURL}timeline/${row.item.feedId}`"
                >{{ row.item.feedId }}</a
              >
            </td>
            <td>{{ row.item.content }}</td>
            <td>
              <a
                target="_blank"
                :href="`/user/${row.item.user.id}`"
                >{{ row.item.user.username }}</a
              >
            </td>
            <td>{{ format_date(row.item.created_at) }}</td>
          </tr>
        </template></v-data-table
      >
      <div class="float-right ma-3">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          circle
          @input="handlePageChange"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen.vue";

export default {
  name: "Tournament",

  components: { LoadingScreen },

  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "feedId" },
        { text: "Konten", value: "content" },
        { text: "Creator", value: "user.username" },
        { text: "Dibuat", value: "created_at" },
      ],
      datas: [],
      currentPage: 1,
      isLoading: true,
      perPage: 0,
      baseWebURL: process.env.VUE_APP_WEB_URL,
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    resetSearch() {
      this.search = "";
      this.currentPage = 1;
      this.fetch();
    },

    doSearch() {
      this.currentPage = 1;
      this.fetch();
    },

    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      let url = `${process.env.VUE_APP_API_URL}/v2/feed/list?page=${this.currentPage}`;

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.isLoading = false;

          this.datas = response.data.result.data;
          this.totalPages = response.data.result.last_page;
          this.perPage = response.data.result.per_page;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.fetch();
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
